/*@import "~angular-vertical-timeline/dist/vertical-timeline.css";*/
/* You can add global styles to this file, and also import other style files */

.dna-modal-campaign .modal-content {
  min-width: 950px;
}

select {
  background-color: #FFFFFF;
  background-image: none;
  border: 1px solid #e5e6e7;
  border-radius: 1px;
  color: inherit;
  display: block;
  padding: 6px 12px;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  width: 25%;
  font-size: 14px;
}

@media (min-width: 1200px) {
  .modal-lg {
      max-width: 1100px;
  }
}

@media (min-width: 1600px) {
  .modal-lg {
      max-width: 1400px;
  }
}

#image-one-pager .card-body {
  padding: 0 !important;
}

.timeline-content {
  cursor: pointer;
}

.margin-bottom {
  margin-bottom: 10px;
}

.margin-date {
  margin-left: -15px;
}

@media (min-width: 1300px) {
  .container {
    max-width: 1200px;
  }
}

@media (min-width: 1500px) {
  .container {
    max-width: 1300px;
  }
}

html, body { height: 100%; }
body {
    margin: 0;
    font-family: "open sans",
    "Helvetica Neue",
    Helvetica,
    Arial,
    "sans-serif";
    font-size: 13px;
    font-weight: normal
}
