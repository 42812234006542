.breadcrumb {
  background-color: transparent;
  margin-bottom: 4rem !important;

  .breadcrumb-item:not(.active) {
    color: #1ab394;
  }
}

.btn-group-xs > .btn,
.btn-xs {
  padding: .25rem .4rem;
  font-size: .75rem;
  line-height: 1;
  border-radius: .2rem;
}

.cursor-pointer {
  cursor: pointer;
}

.popover {
  z-index: 999999999999;
}

.table-borderless-th th {
  border-top: none !important;
}

.block {
  margin-top: 190px;
}

#banner-title {
  position: fixed;
  z-index: 15;
  top: 100px;
  display: block;
}

#banner-nav {
  position: fixed;
  z-index: 15;
  top: 150px;
  transition: 0.5s;
}

#banner-actions {
  position: fixed;
  z-index: 15;
  right: 10%;
  top: 100px;
  transition: 0.5s;
}

.modal-scroll {
  overflow-y: auto;
  max-height: 70vh;
}

.isinvalid {
  border: solid;
  border-width: thin;
  border-color: #dc3545;
}

.comment-text {
  font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif;
  font-size: 14px;
  white-space: pre-line;
}

.mce-content-body {
  height: 100% !important;
}

.highcharts-legend-item-hidden > * {
  text-decoration: none !important;
}

.highcharts-axis-line {
  stroke: #ccd6eb;
}

.highcharts-title {
  color: #676a6c !important;
  fill: #676a6c !important;
  font-weight: normal !important;
}
